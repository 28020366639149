@import "@abgov/web-components/index.css";

.button-anchor {
    border: 1px solid #ccc;
    text-decoration: none;
    /* display: inline; */
    padding: 4px;
    width: 100px;
    text-align: center;
}

body {
    color: #333;
}