.content {
  margin-top: 16px;
}

.app-container {
  /* width: 304px;; */
}

.tooltip-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: -20px;
  margin-right: -16px;
}

.link-wrapper {
  display: flex;
  margin-top: -20px;
  margin-bottom: 12px;
}

.link-wrapper a {
  margin-left: 8px;
}

h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
}

.item-wrapper {
    width: 100%;
    display: inline-block;
}